import { media, styled } from "@styles";
import { css } from "styled-components";

const textProps = css`
  font-size: ${props => props.theme.typography.baseFontSize};
  margin: 0 0 0.5rem 0;
  text-align: left;
`;

export const Wrapper = styled.div`
  background: ${props => props.theme.colors.white};
  padding: 2.5rem;
  text-align: center;
  max-height: 30rem;
  transition: 0.3s;
  position: relative;
  
  :after {
    content: "";
    display: block;
    width: 75%;
    height: 80%;
    top: 0px;
    position: absolute;
    background-size: contain;
    background-image: url('/images/logo.svg');
    background-position: center;
    opacity: 0.06;
    /* object-fit: contain; */
    max-width: 100%;
    background-repeat: no-repeat;
  }
  

  :hover {
    background-color: ${props => props.theme.colors.hoverLightBackground};
  }

  ${media.mediumScreen`
    padding: 1.8rem;
  `}
`;

export const Title = styled.h4`
  text-transform: uppercase;
  font-weight: normal;
  ${textProps}
`;

export const Price = styled.p`
  ${textProps}
`;

export const Image = styled.div`
  width: auto;
  height: auto;
  max-width: 100%;

  > img {
    width: auto;
    height: auto;
    max-width: 100%;
  }
`;
