import { generatePageUrl } from "./utils";

export const BASE_URL = "/";
export const PRODUCTS_PER_PAGE = 6;
export const SUPPORT_EMAIL = "support@example.com";
export const PROVIDERS = {
  BRAINTREE: "Braintree",
  DUMMY: "Cheque",
  STRIPE: "Stripe",
};
export const STATIC_PAGES = [
  {
    label: "About",
    url: generatePageUrl("about"),
  },
];
export const SOCIAL_MEDIA = [
  {
    ariaLabel: "facebook",
    href: "https://www.facebook.com/giftmartindia/",
    path: require("../images/facebook-icon.svg"),
  },
  {
    ariaLabel: "instagram",
    href: "https://www.instagram.com/giftmartindia",
    path: require("../images/instagram-icon.svg"),
  },
  {
    ariaLabel: "twitter",
    href: "https://twitter.com/giftmartindia",
    path: require("../images/twitter-icon.svg"),
  },
  {
    ariaLabel: "whatsapp",
    href: "https://api.whatsapp.com/send?phone=+919619938666",
    path: require("../images/whatsapp-icon.svg"),
  },
  // {
  //   ariaLabel: "youtube",
  //   href: "https://www.youtube.com/channel/UCg_ptb-U75e7BprLCGS4s1g/videos",
  //   path: require("../images/youtube-icon.svg"),
  // },
  {
    ariaLabel: "linkedIn",
    href: "https://in.linkedin.com/in/giftmart-55636448",
    path: require("../images/linkedin-icon.svg"),
  },
  // {
  //   ariaLabel: "india-mart",
  //   href: "https://www.indiamart.com/giftmart-india/",
  //   path: require("../images/india-mart.svg"),
  // },
  // {
  //   ariaLabel: "trade-india",
  //   href: "http://www.corporategiftsinmumbai.com/",
  //   path: require("../images/trade-india.svg"),
  // },
];
export const META_DEFAULTS = {
  custom: [],
  description: "Corporate Giftings",

  image: `${window.location.origin}${require("../images/logo.svg")}`,
  title: "Giftmart India",
  type: "website",
  url: window.location.origin,
};
