import "./scss/index.scss";

import classNames from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";

import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { Loader, ProductsFeatured } from "../../components";
import { generateCategoryUrl } from "../../core/utils";

import {
  ProductsList_categories,
  ProductsList_shop,
  ProductsList_shop_homepageCollection_backgroundImage,
} from "./types/ProductsList";

import { structuredData } from "../../core/SEO/Homepage/structuredData";

// import catalogue from "../../images/catalogue.pdf";
import noPhotoImg from "../../images/no-photo.svg";

const Page: React.FC<{
  loading: boolean;
  categories: ProductsList_categories;
  backgroundImage: ProductsList_shop_homepageCollection_backgroundImage;
  shop: ProductsList_shop;
}> = ({ loading, categories, backgroundImage, shop }) => {
  const categoriesExist = () => {
    return categories && categories.edges && categories.edges.length > 0;
  };

  return (
    <>
      <script className="structured-data-list" type="application/ld+json">
        {structuredData(shop)}
      </script>
      <Carousel showThumbs={false} swipeable={true} dynamicHeight={true} infiniteLoop={true} showStatus={false} className="home-page__carousel">
        <div>
          <img src="https://giftmartindia.com/static/files/MIST-SPRAYER-WEB-BANNER-1.jpg" className="" />
          {/*<p className="legend">Legend 1</p>*/}
        </div>
        <div>
          <img src="https://giftmartindia.com/static/files/MIST-SPRAYER-WEB-BANNER-2.jpg" />
          {/*<p className="legend">Legend 2</p>*/}
        </div>
      </Carousel>
      <div
        className="home-page__hero"
        style={
          backgroundImage
            ? { backgroundImage: `url(${backgroundImage.url})` }
            : null
        }
      >
        <div className="home-page__hero-text">
          {/*<div>*/}
          {/*  <span className="home-page__hero__title">*/}
          {/*    <h1></h1>*/}
          {/*  </span>*/}
          {/*</div>*/}
          {/*<div>*/}
          {/*  <span className="home-page__hero__title">*/}
          {/*    <h1>Download  Catalogue</h1>*/}
          {/*  </span>*/}
          {/*</div>*/}
        </div>
        <div className="home-page__hero-action">
          {loading && !categories ? (
            <Loader />
          ) : (
            categoriesExist() && (

            <a target="_blank" href="https://giftmartindia.com/static/files/catalogue.pdf">
              <button className="button  "><span>Download Catalogue</span></button>
            </a>
              // <Link
              //   to="https://giftmartindia.com/static/files/catalogue.pdf" target="_blank"
              // >
              //   <Button>Download Catalogue</Button>
              // </Link>
            )
          )}
        </div>
      </div>
      <ProductsFeatured />
      {categoriesExist() && (
        <div className="home-page__categories">
          <div className="container">
            <h3>Shop by category</h3>
            <div className="home-page__categories__list">
              {categories.edges.map(({ node: category }) => (
                <div key={category.id}>
                  <Link
                    to={generateCategoryUrl(category.id, category.name)}
                    key={category.id}
                  >
                    <div
                      className={classNames(
                        "home-page__categories__list__image",
                        {
                          "home-page__categories__list__image--no-photo": !category.backgroundImage,
                        }
                      )}
                      style={{
                        backgroundImage: `url(${
                          category.backgroundImage
                            ? category.backgroundImage.url
                            : noPhotoImg
                        })`,
                      }}
                    />
                    <h3>{category.name}</h3>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Page;
