import classNames from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";

import { RichTextContent } from "@components/atoms";
import { Breadcrumb, Breadcrumbs } from "../../components";

interface PageNavigationElement {
  active: boolean;
  label: string;
  url: string;
}

interface PageProps {
  breadcrumbs: Breadcrumb[];
  headerImage: string | null;
  navigation: PageNavigationElement[];
  page: {
    contentJson: any;
    title: string;
  };
}

export const Page: React.FC<PageProps> = ({
                                            breadcrumbs,
                                            headerImage,
                                            navigation,
                                            page,
                                          }) => (
  <div className="article-page">
    <div
      className="article-page__header"
      style={headerImage ? { backgroundImage: `url(${headerImage})` } : null}
    >
      <span className="article-page__header__title">
        <h1>{page.title}</h1>
      </span>
    </div>
    <div className="container">
      <Breadcrumbs breadcrumbs={breadcrumbs}/>
      <div className="article-page__container">
        <div className="article-page__navigation">
          <ul>
            {navigation.map(menuElement => (
              <li
                className={classNames({
                  ["article-page__navigation-element"]: true,
                  ["article-page__navigation-element--active"]:
                  menuElement.active,
                })}
                key={menuElement.url}
              >
                <Link to={menuElement.url}>{menuElement.label}</Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="article-page__content">
          <RichTextContent
            descriptionJson={page.contentJson}
          />
        </div>

      </div>
    </div>
    <div className="container-fluid google-maps">
      <iframe width="100%" height="450"
              src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJlRWa6MTP5zsRFvtIyHFUaWY&key=AIzaSyDTdQPQ0lwy-QgubQEa57bB2b9vCA3CeRo"
              allowFullScreen/>
    </div>
    <div className="container contact-details">
      <div className="article-page__container">
        <div className="article-page__navigation">
          <ul>

            <li
              className="article-page__navigation-element"
            >
              <a href="#">Contact Details</a>
            </li>

          </ul>
        </div>
        <div className="article-page__content">
          <p className="content">
            <span className="title">Customer Queries</span>
            <a className="action" href="mailto:info@giftmartindia.com" target="_top">info@giftmartindia.com</a>
          </p>
          <p className="content">
            <span className="title">Call Us</span>
            <a className="action" href="tel:02223457700">Office Telephone -  (022) 23457700 / 23458800</a>
            <a className="action" href="tel:+919821524100">Mr. Kanti Gada - +91 9821 524100</a>
            <a className="action" href="tel:+919819175166">Mr. Tejas Gada - +919819 175166</a>
            <a className="action" href="tel:+9619938666">Mr. Yash Gada - +91 96199 38666</a>
          </p>
          <p className="content">
            <span>Store Address</span>
            <span>
              178/180, Abdul Rehman St, below Union Bank, near Chippi Chawl, Chippi Chawl, Kalbadevi, Mumbai, Maharashtra 400003
            </span>
          </p>
        </div>

      </div>
    </div>

  </div>
);
export default Page;
